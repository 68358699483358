/* ==========================================================================
   Global
   ========================================================================== */

:root {
    --app-height: 100vh;
}

html,
body {
    width: 100%;
    min-width: 320px;
    color: $color_white;
    background: $color_black5;
}
html {
    overflow-x: hidden;
    scroll-behavior: smooth;
    &.-blocked {
        overflow-y: hidden;
    }
}
body {
    font-size: 6.25%;
    line-height: 1.2;
    height: 100vh;
    height: var(--app-height);

    #root {
        height: 100%;
    }
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    word-wrap: break-word;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    display: block;
    max-width: 100%;
    border: 0;
}

::selection {
    background: rgba($color_black, 0.1);
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: inherit;
    }
    &:focus {
        color: inherit;
        outline-color: $color_black;
    }

    &[href^='tel:'] {
        text-decoration: none;
    }
}

ol {
    list-style-position: inside;
    list-style-type: decimal;

    li + li {
        margin-top: 8px;
    }
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    width: 6px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: $color_grey6;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: $color_grey6;
}

@include respond-above(sm) {
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        width: 12px;
    }
}

.l-main {
    width: 100%;
    overflow: hidden;

    @include respond-below(md) {
        padding-top: $menu_height-mobile;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: $menu_height-mobile;
            background-color: $color_white;
        }
    }
}
