// /* ==========================================================================
// 	 Typography
// 	 ========================================================================== */

body {
    font-family: $font_family-gilroy;
    font-size: 14px;
    line-height: (28 / 14);
    color: $color_black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// /*========================================================================== */

@mixin typo_roboto_32-42 {
    font-family: $font_family-gilroy;
    font-size: 32px;
    line-height: (42 / 32);
}

@mixin typo_roboto_24-36 {
    font-family: $font_family-gilroy;
    font-size: 24px;
    line-height: (36 / 24);
}

@mixin typo_roboto_24-32 {
    font-family: $font_family-gilroy;
    font-size: 24px;
    line-height: (22 / 24);
}

@mixin typo_roboto_24-26 {
    font-family: $font_family-gilroy;
    font-size: 24px;
    line-height: (26 / 24);
}

@mixin typo_roboto_24-22 {
    font-family: $font_family-gilroy;
    font-size: 24px;
    line-height: (22 / 24);
}

@mixin typo_roboto_20-30 {
    font-family: $font_family-gilroy;
    font-size: 20px;
    line-height: (30 / 20);
}

@mixin typo_roboto_20-28 {
    font-family: $font_family-gilroy;
    font-size: 20px;
    line-height: (28 / 20);
}

@mixin typo_roboto_20-27 {
    font-family: $font_family-gilroy;
    font-size: 20px;
    line-height: (27 / 20);
}

@mixin typo_roboto_18-28 {
    font-family: $font_family-gilroy;
    font-size: 18px;
    line-height: (28 / 18);
}

@mixin typo_roboto_18-20 {
    font-family: $font_family-gilroy;
    font-size: 18px;
    line-height: (28 / 18);
}

@mixin typo_roboto_16-24 {
    font-family: $font_family-gilroy;
    font-size: 16px;
    line-height: (24 / 16);
}

@mixin typo_roboto_16-22 {
    font-family: $font_family-gilroy;
    font-size: 16px;
    line-height: (22 / 16);
}

@mixin typo_roboto_16-20 {
    font-family: $font_family-gilroy;
    font-size: 16px;
    line-height: (20 / 16);
}

@mixin typo_roboto_14-28 {
    font-family: $font_family-gilroy;
    font-size: 14px;
    line-height: (28 / 14);
}

@mixin typo_roboto_14-26 {
    font-family: $font_family-gilroy;
    font-size: 14px;
    line-height: (26 / 14);
}
@mixin typo_roboto_14-24 {
    font-family: $font_family-gilroy;
    font-size: 14px;
    line-height: (24 / 14);
}

@mixin typo_roboto_14-20 {
    font-family: $font_family-gilroy;
    font-size: 14px;
    line-height: (20 / 14);
}

@mixin typo_roboto_14-21 {
    font-family: $font_family-gilroy;
    font-size: 14px;
    line-height: 21px;
}

@mixin typo_roboto_14-14 {
    font-family: $font_family-gilroy;
    font-size: 14px;
    line-height: (14 / 14);
}

@mixin typo_roboto_11-18 {
    font-family: $font_family-gilroy;
    font-size: 11px;
    line-height: (18 / 11);
}

// /*========================================================================== */

.t-text {
    @include typo_roboto_32-42;
    color: $color_black;

    &.-f24 {
        @include typo_roboto_24-32;
    }

    &.-f24_36 {
        @include typo_roboto_24-36;
    }

    &.-f24_26 {
        @include typo_roboto_24-26;
    }

    &.-f24_22 {
        @include typo_roboto_24-22;
    }

    &.-f20 {
        @include typo_roboto_20-28;
    }

    &.-f20_30 {
        @include typo_roboto_20-28;
    }

    &.-f20_27 {
        @include typo_roboto_20-27;
    }

    &.-f18 {
        @include typo_roboto_18-28;
    }

    &.-f18 {
        @include typo_roboto_18-20;
    }

    &.-f16 {
        @include typo_roboto_16-24;
    }

    &.-f16_22 {
        @include typo_roboto_16-22;
    }

    &.-f16_20 {
        @include typo_roboto_16-20;
    }

    &.-f14 {
        @include typo_roboto_14-28;
    }

    &.-f14_26 {
        @include typo_roboto_14-26;
    }
    &.-f14_24 {
        @include typo_roboto_14-24;
    }

    &.-f14_20 {
        @include typo_roboto_14-20;
    }

    &.-f14_21 {
        @include typo_roboto_14-21;
    }

    &.-f14_14 {
        @include typo_roboto_14-14;
    }

    &.-f11 {
        @include typo_roboto_11-18;
    }

    @include respond-below(md) {
    }

    @include respond-below(sm) {
    }
}
