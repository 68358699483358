.c-chat {
    &_message,
    &_ownMessage {
        flex-direction: column;
        p {
            width: fit-content;
            max-width: 80%;
            padding: 10px 16px;
            margin-bottom: 4px;
            letter-spacing: 0.25px;
            border-radius: 8px;
            font-weight: 600;
        }

        .c-attachment {
            &_name {
                font-size: 14px;
                border-radius: 8px;
            }
        }
    }
    &_ownMessage {
        display: flex;
        align-items: flex-end;

        p {
            color: $color_green-dark;
            background-color: rgba($color_green, 0.1);
        }

        .c-resendMessage,
        .c-resendMessage_btn {
            color: $color_red-dark;
            font-weight: 600;
        }

        .c-resendMessage {
            align-items: center;
            &_btn {
                text-decoration: underline;
                margin-left: 3px;
            }

            &_icon {
                margin-right: 8px;
            }
        }
    }
    &_systemMessage {
        flex-direction: column;
        margin-top: 24px;
        margin-right: -4px;
        margin-left: -4px;
        padding-bottom: 24px;

        p {
            color: $color_black5;
            letter-spacing: 0.25px;
            font-weight: 600;
        }
        .c-chat_time {
            margin-left: 0;
            font-weight: 600;
        }

        &.-borderBottom {
            border-bottom: 1px solid $color_grey-pale;
        }
    }

    &_message {
        p {
            color: $color_black5;
            background-color: $color_grey5;
        }

        .c-attachment {
            &_name {
                font-size: 14px;
            }
            &_downloadBtn {
                order: 1;
            }
        }
    }
    &_time {
        margin-top: 12px;
        margin-left: 8px;
        letter-spacing: 0.25px;
        font-weight: 600;

        &.-own {
            margin-right: 8px;
            margin-left: 0px;
        }
    }
}
