/* ==========================================================================
	 Utilities
	 ========================================================================== */

.-uppercase {
    text-transform: uppercase;
}
.-italic {
    font-style: italic;
}
.-withUnderline {
    text-decoration: underline;
}

/*========================================================================== */

.-wNormal {
    font-weight: 400;
}
.-wSBold {
    font-weight: 500;
}
.-wMBold {
    font-weight: 600;
}
.-wBold {
    font-weight: 700;
}
.-wLBold {
    font-weight: 900;
}

@include respond-below(sm) {
    .-wNormalSm {
        font-weight: 400;
    }
}

/*========================================================================== */

.-tCenter {
    text-align: center;
}
.-tLeft {
    text-align: left;
}
.-tRight {
    text-align: right;
}

@include respond-below(md) {
    .-tCenterMd {
        text-align: center;
    }
}

@include respond-below(sm) {
    .-tCenterSm {
        text-align: center;
    }
    .-tLeftSm {
        text-align: left;
    }
}

/*========================================================================== */

$ls: -4, -3, 0, 2, 3, 4;

@each $v in $ls {
    .-lSpacing#{$v} {
        letter-spacing: calc(#{$v} * 0.1px);
    }
}

$lsh: 2, 3, 4;

@each $v in $lsh {
    .-lSpacing#{$v}h {
        letter-spacing: calc(#{$v} * 0.1px + 0.05px);
    }
}

/*========================================================================== */

.-cWhite {
    color: $color_white;
}
.-cBlack {
    color: $color_black;
}
.-cBlack2 {
    color: $color_black2;
}
.-cBlack3 {
    color: $color_black3;
}
.-cBlack4 {
    color: $color_black4;
}
.-cBlack5 {
    color: $color_black5;
}
.-cBlack6 {
    color: $color_black6;
}

.-cGrey {
    color: $color_grey;
}
.-cGrey2 {
    color: $color_grey2;
}
.-cGrey3 {
    color: $color_grey3;
}
.-cGrey4 {
    color: $color_grey4;
}
.-cGrey5 {
    color: $color_grey5;
}
.-cGrey6 {
    color: $color_grey6;
}

.-cGreyDark {
    color: $color_grey;
}

.-cGreen {
    color: $color_green;
}

.-cDarkGreen {
    color: $color_green-dark;
}

.-cRed {
    color: $color_red;
}

@include respond-below(sm) {
}

/*========================================================================== */

.-bgWhite {
    background-color: $color_white;
}
.-bgBlack {
    background-color: $color_black;
}
.-bgBlack2 {
    background-color: $color_black2;
}
.-bgBlack3 {
    background-color: $color_black3;
}
.-bgBlack4 {
    background-color: $color_black4;
}
.-bgBlack5 {
    background-color: $color_black5;
}

.-bgGrey {
    background-color: $color_grey;
}
.-bgGrey2 {
    background-color: $color_grey2;
}
.-bgGrey3 {
    background-color: $color_grey3;
}
.-bgGrey4 {
    background-color: $color_grey4;
}
.-bgGrey5 {
    background-color: $color_grey5;
}
.-bgGreyDark {
    background-color: $color_grey;
}

.-bgGreen {
    background-color: $color_green;
}

.-bgDarkGreen {
    background-color: $color_green-dark;
}

.-bgRed {
    background-color: $color_red;
}

/*========================================================================== */

.-fullWidth {
    width: 100%;
}

.-smFullWidth {
    @include respond-below(sm) {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}
.-mdFullWidth {
    @include respond-below(md) {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

.-smFullImgWidth {
    @include respond-below(xs) {
        width: 100vw !important;
        max-width: none;
        margin-left: calc(50% - 50vw);
    }
}
