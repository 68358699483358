/* ==========================================================================
   Fonts
   ========================================================================== */

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Regular.ttf') format('truetype'),
    url('../../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Medium.ttf') format('truetype'),
    url('../../fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Bold.ttf') format('truetype'),
    url('../../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Black.ttf') format('truetype'),
    url('../../fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/*========================================================================== */

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/gilroy/Radomir\ Tinkov\ -\ Gilroy-Light.otf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/gilroy/Radomir\ Tinkov\ -\ Gilroy-Regular.otf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/gilroy/Radomir\ Tinkov\ -\ Gilroy-Medium.otf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/gilroy/Radomir\ Tinkov\ -\ Gilroy-Bold.otf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
