.c-btn {
    padding: 3px 8px 4px;
    color: $color_white;
    border-radius: 2px;
    &.-green {
        background-color: $color_green;
    }
    &.-red {
        background-color: $color_red;
    }
    &.-black4 {
        background-color: $color_black4;
    }
    &.-grey {
        background-color: $color_grey;
    }
    &.-lg {
        width: 180px;
        padding: 8px 10.14px 8.6px;
    }
}
.c-controlBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    padding: 16px;
    background-color: rgba($color_white, 0.15);
    border-radius: 8px;
    backdrop-filter: blur(48px);
    @include respond-below(md) {
        width: 48px;
        height: 48px;
        padding: 13px;
    
    }
    &.-lg {
        width: 72px;
        @include respond-below(md) {
            width: 64px;

        }
    }
    &.-active {
        background-color: $color_red-dark;

        &:hover {
            background-color: $color_red-dark2;
        }
    }

    &:disabled {
        cursor: unset;
    }

    &.-bgGreen {
        background-color: $color_green-dark;
        &:disabled {
            background-color: $color_grey6;
        }
        &:hover {
            background-color: $color_green-dark2;
            &:disabled {
                background-color: $color_grey6;
            }
        }
    }

    &:hover {
        background-color: rgba($color_white, 0.07);

        &:disabled {
            background-color: rgba($color_white, 0.15);
        }
    }

    &.-endCall {
        @include respond-below(md) {
            order: -1;
            margin-right: 12px;
            margin-left: 0;
        }

        &:hover {
            background-color: $color_red-dark2;
        }
    }
}

.c-roundBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $color_grey-pale;

    img {
        width: 16px;
        height: 16px;
    }

    &.-darkBgc {
        background-color: $color_grey-dark;
        border: none;
    }

    &.-camIcon {
        margin-left: 8px;

        &:disabled {
            cursor: unset;
            opacity: 0.4;
        }
    }
}
