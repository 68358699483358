/* ==========================================================================
	 Vars
	 ========================================================================== */

/* Colors */
$color_white: #fff;
$color_black: #000;
$color_black2: #2a2e3e;
$color_black3: #050607;
$color_black4: #282c3c;
$color_black5: #001524;
$color_black6: #182f40;

$color_grey: #9295a3;
$color_grey2: #e6e6e6;
$color_grey3: #dcdede;
$color_grey4: #586976;
$color_grey5: #f4f6f9;
$color_grey6: #d3dce2;

$color_grey-pale: #f0f2f5;

$color_grey-dark: #182f40;
$color_green: #459a75;
$color_green-dark: #147865;
$color_green-dark2: #105e4f;
$color_red: #d23b3b;
$color_red-dark: #c31e14;
$color_red-dark2: #a81a11;
$color_orange-light: #ffb05f;

/* Fonts */
$font_family-roboto: 'Roboto', sans-serif;
$font_family-gilroy: 'Gilroy', sans-serif;

/* Breakpoints */
$breakpoints: (
    xxxxs: 320px,
    xxxs: 360px,
    xxs: 375px,
    xs: 576px,
    sm: 768px,
    md: 992px,
    lg: 1230px,
    xl: 1366px,
    xxl: 1680px,
);

/* Distances */
$menu_height: 141px;
$menu_height_mobile: 68px;

/* Other */
$image_path: '../../../images';
