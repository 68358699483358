.visit_info_page_container.unexpected_error_page_container {
    height: auto;
    min-height: 100%;
}

.unexpected_error_info {
    padding-bottom: 32px;
}

.unexpected_error {
    &_message,
    &_stack {
        white-space: pre-line;
    }
}
