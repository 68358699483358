.visit_info {
    background-color: $color_white;

    &_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &_title {
        color: $color_black6;
        font-weight: 700;
    }

    &_description {
        color: $color_grey4;
        font-weight: 600;
        max-width: 448px;
    }

    &_thank_you_text {
        color: $color_black5;
        font-weight: 700;
    }
}
