/* ==========================================================================
	Distances (between elements in list)
	========================================================================== */

/* Left
========================================================================== */
$dl: 0, 8, 10, 12, 14, 42;

@each $d in $dl {
    .-dl#{$d} {
        & > * {
            & + * {
                margin-left: #{$d}px;
            }
        }
    }
}

/* Left - xl 
========================================================================== */

$dl_xl: 0, 8, 10;

@each $d in $dl_xl {
    .-dl#{$d}xl {
        & > * {
            & + * {
                @include respond-below(xl) {
                    margin-left: #{$d}px;
                }
            }
        }
    }
}

/* Left - lg 
========================================================================== */

$dl_lg: 0, 8, 10;

@each $d in $dl_lg {
    .-dl#{$d}lg {
        & > * {
            & + * {
                @include respond-below(lg) {
                    margin-left: #{$d}px;
                }
            }
        }
    }
}

/* Left - md
========================================================================== */

$dl_md: 0, 8, 10;

@each $d in $dl_md {
    .-dl#{$d}md {
        & > * {
            & + * {
                @include respond-below(md) {
                    margin-left: #{$d}px;
                }
            }
        }
    }
}

/* Left - sm 
========================================================================== */

$dl_sm: 0, 8, 10;

@each $d in $dl_sm {
    .-dl#{$d}sm {
        & > * {
            & + * {
                @include respond-below(sm) {
                    margin-left: #{$d}px;
                }
            }
        }
    }
}

/* Top
========================================================================== */

$dt: 0, 8, 10;

@each $d in $dt {
    .-dt#{$d} {
        & > * {
            & + * {
                margin-top: #{$d}px;
            }
        }
    }
}

/* Top - md
========================================================================== */

$dt_md: 0, 8, 10;

@each $d in $dt_md {
    .-dt#{$d}md {
        & > * {
            & + * {
                @include respond-below(md) {
                    margin-top: #{$d}px;
                }
            }
        }
    }
}

/* Top - sm
========================================================================== */

$dt_sm: 0, 8, 10;

@each $d in $dt_sm {
    .-dt#{$d}sm {
        & > * {
            & + * {
                @include respond-below(sm) {
                    margin-top: #{$d}px;
                }
            }
        }
    }
}
