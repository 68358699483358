.c-endConversationPage {
    background-color: $color_black5;
    padding: 8px;
    height: 100%;

    @include respond-above(md) {
        padding: 24px;
    }

    &_wrapper {
        border-radius: 8px;
        align-items: center;
        height: 100%;
        justify-content: center;

        @include respond-above(md) {
            border-radius: 16px;
        }
        &_logo {
            align-items: center;
            &_hashtag {
                font-size: 24px;
                font-weight: 700;

                @include respond-above(md) {
                    font-size: 32px;
                }
            }
        }

        &_header,
        &_thanks {
            font-weight: 700;
        }

        &_header {
            @include typo_roboto_20-30;
        }

        &_subtitle {
            color: $color_grey4;
            max-width: 448px;
            font-weight: 600;
            padding: 0 16px;
        }
    }
}
