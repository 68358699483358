.c-info {
    width: 100%;
    height: 100%;
    padding: 15px 16px;
    border-radius: 16px;
    &.-callerCameraOff {
        height: 100%;
        color: $color-grey-dark;

        @include respond-below(md) {
            height: 100%;
        }
        .c-info_iconWrapper {
            width: 168px;
            height: 168px;
            border-radius: 50%;
        }
        .c-info_icon {
            width: 64px;
            height: 64px;
        }

        span {
            color: $color_grey-dark;
        }
    }
    &.-callerAbsent {
        height: 100%;

        @include respond-below(md) {
            width: 100%;
        }
        .c-info_text {
            max-width: 514px;
        }
    }
    &.-ownCameraOff {
        z-index: 10;
        position: absolute;
        top: 24px;
        right: 24px;
        width: auto;
        height: 48px;
        border-radius: 8px;

        @include respond-below(md) {
            top: 16px;
            right: 16px;
            height: 32px;
            border-radius: 4px;
            padding: 7px 10px;
        }

        span {
            color: $color_grey-dark;
        }
        .c-info_icon {
            width: 16px;
            height: 16px;
        }

        &.-callerOn {
            background-color: rgba(255, 255, 255, 0.2);
            span {
                color: $color_white;
            }
        }

        &.-fullScreenMode {
            @include respond-above(md) {
                top: 36px;
            }
        }
    }

    .c-controlBtn {
        position: absolute;
        left: -12px;
        top: 0;
        transform: translate3d(-100%, 0, 0);
        background-color: $color_grey5;
        width: 48px;
        height: 48px;
        padding: 0;
    }
}
