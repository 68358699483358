/* ==========================================================================
	 Spacings
	 ========================================================================== */

/* Padding top 
========================================================================== */

$pt: 0, 2, 4, 8, 16, 24, 30, 32, 64, 128;

@each $p in $pt {
    .-pt#{$p} {
        padding-top: #{$p}px;
    }
}

/* Padding top - lg
========================================================================== */

$pt_lg: 0, 2, 4, 8, 16, 32, 64, 128;

@include respond-below(lg) {
    @each $p in $pt_lg {
        .-pt#{$p}lg {
            padding-top: #{$p}px;
        }
    }
}

/* Padding top - md
========================================================================== */

$pt_md: 0, 2, 4, 8, 16, 32, 64, 128;

@include respond-below(md) {
    @each $p in $pt_md {
        .-pt#{$p}md {
            padding-top: #{$p}px;
        }
    }
}

/* Padding top - sm
========================================================================== */

$pt_sm: 0, 2, 4, 8, 16, 32, 64, 128;

@include respond-below(sm) {
    @each $p in $pt_sm {
        .-pt#{$p}sm {
            padding-top: #{$p}px;
        }
    }
}

/* Padding bottom 
========================================================================== */

$pb: 0, 2, 4, 8, 16, 24, 32, 64, 128;

@each $p in $pb {
    .-pb#{$p} {
        padding-bottom: #{$p}px;
    }
}

/* Padding bottom - lg
========================================================================== */

$pb_lg: 0, 2, 4, 8, 16, 32, 64, 128;

@include respond-below(lg) {
    @each $p in $pb_lg {
        .-pb#{$p}lg {
            padding-bottom: #{$p}px;
        }
    }
}

/* Padding bottom - md
========================================================================== */

$pb_md: 0, 2, 4, 8, 16, 32, 64, 128;

@include respond-below(md) {
    @each $p in $pb_md {
        .-pb#{$p}md {
            padding-bottom: #{$p}px;
        }
    }
}

/* Padding bottom - sm
========================================================================== */

$pb_sm: 0, 2, 4, 8, 16, 24, 32, 64, 128;

@include respond-below(sm) {
    @each $p in $pb_sm {
        .-pb#{$p}sm {
            padding-bottom: #{$p}px;
        }
    }
}

/* Margin top
========================================================================== */

$mt: 0, 2, 4, 8, 12, 14, 16, 20, 24, 30, 32, 40, 48, 56, 64, 128, 144;

@each $m in $mt {
    .-mt#{$m} {
        margin-top: #{$m}px;
    }
}

/* Margin top - md
========================================================================== */

$mt_md: 0, 2, 4, 8, 16, 24, 32, 64, 128;

@include respond-below(md) {
    @each $m in $mt_md {
        .-mt#{$m}md {
            margin-top: #{$m}px;
        }
    }
}

/* Margin top - sm 
========================================================================== */

$mt_sm: 0, 2, 4, 8, 16, 32, 64, 128;

@include respond-below(sm) {
    @each $m in $mt_sm {
        .-mt#{$m}sm {
            margin-top: #{$m}px;
        }
    }
}

/* Margin bottom
========================================================================== */

$mb: 0, 2, 4, 8, 12, 16, 24, 32, 40, 48, 64, 72, 128;

@each $m in $mb {
    .-mb#{$m} {
        margin-bottom: #{$m}px;
    }
}

/* Margin bottom - lg
========================================================================== */

$mb_lg: 0, 2, 4, 8, 16, 32, 64, 128;

@include respond-below(lg) {
    @each $m in $mb_lg {
        .-mb#{$m}lg {
            margin-bottom: #{$m}px;
        }
    }
}

/* Margin bottom - md
========================================================================== */

$mb_md: 0, 2, 4, 8, 16, 32, 48, 64, 128;

@include respond-below(md) {
    @each $m in $mb_md {
        .-mb#{$m}md {
            margin-bottom: #{$m}px;
        }
    }
}

/* Margin bottom - sm
========================================================================== */
$mb_sm: 0, 2, 4, 8, 16, 32, 64, 128;

@include respond-below(sm) {
    @each $m in $mb_sm {
        .-mb#{$m}sm {
            margin-bottom: #{$m}px;
        }
    }
}
