.c-lostConnectingInfo {
    display: none !important;
    position: absolute;
    width: 100%;
    max-width: 351px;
    padding: 18px 20px;
    color: $color_white;
    background-color: $color_grey-dark;
    bottom: 65px;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 8px;
    font-weight: 600;
    z-index: 10;
    justify-content: flex-start;

    &_reconnectingIcon {
        animation: reconnecting 1.5s linear infinite;
    }

    &_connectionStatusIcon {
        margin-right: 15px;
    }

    &_text {
        color: $color_white;
        font-weight: 600;
        line-height: 21px;
    }
}

.c-lostConnectingInfo.-active {
    display: flex !important;
}

@keyframes reconnecting {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
