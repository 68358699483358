.c-homepage {
    min-height: 100%;
}
.c-mainFirst {
    padding: 72px 72px 80px;
    &_title {
        max-width: 509px;
    }
    &_text {
        max-width: 509px;
    }
}

.c-mainSecond {
    padding: 56px 72px 64px;
    &_text {
        max-width: 471px;
    }
}
