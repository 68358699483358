.c-attachment {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    &_downloadBtn {
        margin: 0 8px;
        padding: 8px;
        display: grid;
        place-items: center;
        border: 1px solid $color_grey-pale;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        cursor: pointer;
        flex-shrink: 0;
    }

    &_removeBtn {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        background-color: rgba($color_black5, 0.1);
        display: grid;
        place-items: center;
        border-radius: 50%;
        cursor: pointer;
        margin-left: 8px;
    }

    &_name {
        background-color: $color_grey5;
        padding: 12px 16px;
        border-radius: 32px;
        font-weight: 700;
        align-items: center;
        font-size: 12px;
        word-break: break-all;

        &_icon {
            margin-right: 10px;

            &.-loading {
                animation: loading 1s linear infinite;
            }
        }
    }
}

@keyframes loading {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
