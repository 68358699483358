/* ==========================================================================
	Global 
	========================================================================== */

.-scroll-lock,
.-modalOpened {
    width: 100%;
    height: 100%;
    padding-right: 0 !important;
    overflow: hidden;
}

/*========================================================================== */

