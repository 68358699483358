.c-container {
    background-color: $color_white;
    border-radius: 12px;
}
.c-controls {
    padding: 40px 0;
    background: rgb(18, 55, 61);
    background: radial-gradient(
        circle,
        rgba(18, 55, 61, 1) 0%,
        rgba(15, 37, 50, 1) 100%
    );
    border-radius: 16px;

    @include respond-below(md) {
        margin: 8px;
        padding: 24px 0;
    }

    .c-lostConnectingInfo {
        bottom: 130px;
        margin: 0;

        @include respond-below(md) {
            min-width: 328px;
        }

        &_text {
            line-height: 21px !important;
        }
    }

    &.-fullScreen {
        padding: 40px 100px;
        position: absolute;
        left: 50%;
        bottom: 40px;
        transform: translate3d(-50%, 0, 0);
        &.-callerCamOn {
            background: transparent;
        }
    }

    &.-callerCamOn {
        @include respond-below(md) {
            background: transparent;
            position: absolute;
            left: 50%;
            bottom: 40px;
            transform: translate3d(-50%, 0, 0);
            padding: 0;
        }
    }
}
