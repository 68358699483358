.c-visitPage {
    &_container {
        height: 100%;

        @include respond-below(md) {
            padding: 0px;
        }

        .c-wrapper {
            height: 100%;

            @include respond-below(md) {
                margin: 0;
            }

            .fullSize {
                position: relative;
                display: flex;
                flex-direction: column;
                @include respond-below(md) {
                    height: 100% !important;
                    padding: 0 !important;
                }
            }
        }
    }
    &_videos {
        position: relative;
        width: 100%;
        height: 100%;

        @include respond-below(md) {
            padding: 8px 8px 0;
        }

        &.-callerCamOn {
            @include respond-below(md) {
                padding: 0;
            }
        }

        &.-fullScreen {
            max-height: unset;
            padding: 24px 12px;
        }

        .c-ownCameraOn {
            position: relative;

            .c-controlBtn {
                z-index: 1;
                position: absolute;
                right: 106px;
                top: 24px;
                transform: translate3d(-100%, 0, 0);
                background-color: rgba(255, 255, 255, 0.15);
                width: 48px;
                height: 48px;
                padding: 0;

                @include respond-above(sm) {
                    right: 208px;
                }
            }
        }
    }
    &_video {
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;

        &.-caller {
            width: 100%;
            height: 100%;
            min-height: 200px;

            @include respond-below(md) {
                height: 100%;
                width: 100%;
            }

            div {
                border-radius: 16px;
                -webkit-border-radius: 16px;
                -moz-border-radius: 16px;

                video {
                    border-radius: 16px;
                    -webkit-border-radius: 16px;
                    -moz-border-radius: 16px;
                    margin: 0;

                    @include respond-below(md) {
                        border-radius: 0;
                        -webkit-border-radius: 0;
                        -moz-border-radius: 0;
                    }
                }

                @include respond-below(md) {
                    border-radius: 0;
                }
            }
        }
        &.-own {
            position: absolute;
            top: 24px;
            right: 24px;
            z-index: 1;
            width: 224px;
            height: 130px;
            padding: 4px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 8px;
            box-shadow: 0px 16px 32px rgba(0, 21, 36, 0.4);
            backdrop-filter: blur(24px);

            @include respond-below(md) {
                top: 16px;
                right: 16px;
            }
        }
    }
    &_chat {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 16px;
        background: $color_white;
        border-radius: 16px;
        backdrop-filter: blur(32px);
        display: flex;
        flex-direction: column;

        @include respond-below(md) {
            position: fixed;
            bottom: 0;
            left: 0;
            border-radius: 16px 16px 0 0;
            height: calc(100% - 24px);
            z-index: 11;
            padding: 24px 16px 16px;
        }

        .c-chatOnlyMode_wrapper {
            height: 100%;
            position: relative;

            .c-fileOversizeMessage {
                position: absolute;
                width: 100%;
                max-width: 380px;
                padding: 18px 22px;
                color: $color_white;
                background-color: $color_red-dark;
                bottom: 64px;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                border-radius: 8px;
                font-weight: 600;
                z-index: 10;
                animation: showMessage 0.4s ease-out;

                @include respond-below(sm) {
                    bottom: 50px;
                }

                @include respond-below(xxs) {
                    bottom: 36px;
                }

                &_icon {
                    margin-right: 10px;
                    cursor: pointer;
                }

                &.-videoMode {
                    bottom: 26px;

                    @include respond-below(md) {
                        bottom: 32px;
                    }

                    @include respond-below(xxs) {
                        bottom: 18px;
                    }
                }
            }
        }

        &Title {
            position: relative;
            padding: 8px 8px 16px;
            border-bottom: 1px solid $color_grey-pale;

            &::after {
                content: '';
                position: absolute;
                bottom: -51px;
                left: 0;
                width: calc(100% - 8px);
                height: 50px;
                -webkit-box-shadow: inset 0px 50px 39px -6px #ffffff;
                box-shadow: inset 0px 50px 39px -6px #ffffff;
                z-index: 1;
            }

            @include respond-below(md) {
                padding: 0px 0px 16px;
            }

            &_header {
                @include respond-below(md) {
                    max-width: 80% !important;
                }
            }

            .c-chatControllers {
                &_closeBtn {
                    position: absolute;
                    right: 0;
                    top: -8px;
                }
            }
        }
        &Content {
            height: 100%;
            padding-left: 16px;
            padding-right: 16px;
            overflow-y: auto;
            padding-bottom: 0;
        }

        &Area {
            width: 100%;
            position: relative;
            flex-wrap: wrap;
            padding-top: 16px;

            &_textarea {
                resize: none;
                width: 100%;
                height: 56px;
                min-height: 56px;
                padding: 15px 40px 15px 20px;
                border: 1px solid $color_grey6;
                border-radius: 8px;
                cursor: text;
                &.-disabled {
                    background: $color_grey5;
                    border: 1px solid $color_grey5;
                    cursor: default;
                }

                &::placeholder {
                    color: $color_grey6;
                }
                &.-active {
                    border: 1px solid $color_grey4;
                }
                caret-color: $color_green-dark;

                &.-customInput {
                    display: block;
                    height: auto;
                    width: 100%;
                    max-height: 120px;
                    overflow-y: auto;

                    .attachment {
                        display: inline-block;
                        margin-bottom: 8px;
                    }

                    .c-CustomInput {
                        display: inline-block;
                        height: auto;
                        word-break: break-all;
                        &:not(.-disabled) {
                            cursor: text;
                        }

                        &:empty {
                            &::before {
                                content: 'Napisz wiadomość...';
                                color: $color_grey6;
                            }
                        }

                        &.-eng {
                            &:empty {
                                &::before {
                                    content: 'Type a message...';
                                    color: $color_grey6;
                                }
                            }
                        }
                        &.-disabled.-doctorChat {
                            background: $color_grey5;
                            &:empty {
                                &::before {
                                    content: 'Poczekaj na pacjenta';
                                    color: $color_grey6;
                                }
                            }

                            &.-eng {
                                &:empty {
                                    &::before {
                                        content: 'Wait for the patient';
                                        color: $color_grey6;
                                    }
                                }
                            }
                        }
                        &.-disabled.-patientChat {
                            background: $color_grey5;
                            &:empty {
                                &::before {
                                    content: 'Poczekaj na lekarza';
                                    color: $color_grey6;
                                }
                            }

                            &.-eng {
                                &:empty {
                                    &::before {
                                        content: 'Wait for the doctor';
                                        color: $color_grey6;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &_label {
                position: absolute;
                right: 19px;
                bottom: 19px;
                cursor: pointer;

                &.-disabled {
                    cursor: unset;
                }
            }

            &_fileInput {
                display: none;
            }

            .c-fileUploadInfo {
                width: 100%;
            }
        }
    }

    // CHAT ONLY MODE STYLES
    &_chat.-chatOnlyMode {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0;
        height: 100%;
        z-index: 11;
        max-height: unset;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        max-width: 1440px;
        padding-top: 24px;
        padding-bottom: 24px;
        margin: 0 auto;
        justify-content: space-between;

        @include respond-above(md) {
            background-color: $color_black5;
        }

        .c-chatOnlyMode_wrapper {
            max-width: 1000px;
            background-color: $color_white;
            width: 100%;
            border-radius: 16px;
            position: relative;
            width: 100%;
            height: 100%;
            padding: 16px;
            background: $color_white;
            border-radius: 16px;
            backdrop-filter: blur(32px);
            flex-direction: column;

            .c-visitPage_chatTitle {
                &_header {
                    align-items: center;
                    @include respond-below(md) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    &_endCallBtn,
                    &_callDetailsBtn {
                        font-family: 'Gilroy';
                        font-weight: 600;
                        font-size: 14px;
                    }

                    &_endCallBtn {
                        color: $color_red;
                        padding-right: 16px;
                        margin-right: 12px;
                        border-right: 1px solid $color_grey-pale;
                    }

                    &_callDetailsBtn {
                        color: $color_green;
                    }
                }
            }

            .c-visitPage_chatContent {
                position: relative;

                .c-info {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
        }

        .c-callDetails {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 21px 16px;
            background-color: $color_black5;

            @include respond-above(md) {
                position: relative;
                width: 500px;
            }

            &_header {
                border-bottom: 1px solid rgba($color_white, 0.15);
                padding-bottom: 21px;
                .t-text {
                    color: $color_white;
                    font-weight: 600;
                    line-height: 26px;
                    margin-left: 16px;
                }
            }

            &_content {
                position: relative;
                padding: 21px 16px;
                height: 100%;
                font-weight: 600;

                .t-text {
                    color: $color_white;
                    line-height: 24px;
                }

                &_prefix {
                    color: $color_grey6;
                    margin-right: 2px;
                }

                &_logo {
                    width: 110px;
                    position: absolute;
                    bottom: 56px;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    flex-direction: column;
                    align-items: center;
                    font-weight: 600;

                    @include respond-below(md) {
                        bottom: 40px;
                    }
                }
            }
        }
    }
}

@keyframes showMessage {
    from {
        bottom: -50px;
        opacity: 0;
    }
    to {
        bottom: 45px;
        opacity: 1;
    }
}
